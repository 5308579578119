var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (t, e) {
  exports = e();
}(self, function () {
  return (() => {
    var t = {
      771: (t, e, n) => {
        "use strict";

        n.d(e, {
          Z: () => a
        });
        var i = n(645),
            r = n.n(i)()(function (t) {
          return t[1];
        });
        r.push([t.id, ".cdx-text-variant--call-out .ce-block__content {\n  border: 1px solid rgb(255, 135, 135);\n  padding: 8px 16px;\n  border-radius: 6px;\n  box-shadow: 0 0 0 5px rgba(255, 120, 84, 0.16);\n  margin-top: 0.6em;\n  margin-bottom: 0.6em;\n}\n\n.cdx-text-variant--citation {\n  font-style: italic;\n}\n\n.cdx-text-variant--details {\n  font-size: 0.84em;\n}\n\n.cdx-text-variant__toggler svg {\n  width: 20px;\n  height: 20px;\n}\n", ""]);
        const a = r;
      },
      645: t => {
        "use strict";

        t.exports = function (t) {
          var e = [];
          return e.toString = function () {
            return this.map(function (e) {
              var n = t(e);
              return e[2] ? "@media ".concat(e[2], " {").concat(n, "}") : n;
            }).join("");
          }, e.i = function (t, n, i) {
            "string" == typeof t && (t = [[null, t, ""]]);
            var r = {};
            if (i) for (var a = 0; a < (this || _global).length; a++) {
              var o = (this || _global)[a][0];
              null != o && (r[o] = !0);
            }

            for (var s = 0; s < t.length; s++) {
              var c = [].concat(t[s]);
              i && r[c[0]] || (n && (c[2] ? c[2] = "".concat(n, " and ").concat(c[2]) : c[2] = n), e.push(c));
            }
          }, e;
        };
      },
      379: (t, e, n) => {
        "use strict";

        var i,
            r = function () {
          var t = {};
          return function (e) {
            if (void 0 === t[e]) {
              var n = document.querySelector(e);
              if (window.HTMLIFrameElement && n instanceof window.HTMLIFrameElement) try {
                n = n.contentDocument.head;
              } catch (t) {
                n = null;
              }
              t[e] = n;
            }

            return t[e];
          };
        }(),
            a = [];

        function o(t) {
          for (var e = -1, n = 0; n < a.length; n++) if (a[n].identifier === t) {
            e = n;
            break;
          }

          return e;
        }

        function s(t, e) {
          for (var n = {}, i = [], r = 0; r < t.length; r++) {
            var s = t[r],
                c = e.base ? s[0] + e.base : s[0],
                l = n[c] || 0,
                d = "".concat(c, " ").concat(l);
            n[c] = l + 1;
            var u = o(d),
                p = {
              css: s[1],
              media: s[2],
              sourceMap: s[3]
            };
            -1 !== u ? (a[u].references++, a[u].updater(p)) : a.push({
              identifier: d,
              updater: v(p, e),
              references: 1
            }), i.push(d);
          }

          return i;
        }

        function c(t) {
          var e = document.createElement("style"),
              i = t.attributes || {};

          if (void 0 === i.nonce) {
            var a = n.nc;
            a && (i.nonce = a);
          }

          if (Object.keys(i).forEach(function (t) {
            e.setAttribute(t, i[t]);
          }), "function" == typeof t.insert) t.insert(e);else {
            var o = r(t.insert || "head");
            if (!o) throw new Error("Couldn't find a style target. This probably means that the value for the 'insert' parameter is invalid.");
            o.appendChild(e);
          }
          return e;
        }

        var l,
            d = (l = [], function (t, e) {
          return l[t] = e, l.filter(Boolean).join("\n");
        });

        function u(t, e, n, i) {
          var r = n ? "" : i.media ? "@media ".concat(i.media, " {").concat(i.css, "}") : i.css;
          if (t.styleSheet) t.styleSheet.cssText = d(e, r);else {
            var a = document.createTextNode(r),
                o = t.childNodes;
            o[e] && t.removeChild(o[e]), o.length ? t.insertBefore(a, o[e]) : t.appendChild(a);
          }
        }

        function p(t, e, n) {
          var i = n.css,
              r = n.media,
              a = n.sourceMap;
          if (r ? t.setAttribute("media", r) : t.removeAttribute("media"), a && "undefined" != typeof btoa && (i += "\n/*# sourceMappingURL=data:application/json;base64,".concat(btoa(unescape(encodeURIComponent(JSON.stringify(a)))), " */")), t.styleSheet) t.styleSheet.cssText = i;else {
            for (; t.firstChild;) t.removeChild(t.firstChild);

            t.appendChild(document.createTextNode(i));
          }
        }

        var h = null,
            f = 0;

        function v(t, e) {
          var n, i, r;

          if (e.singleton) {
            var a = f++;
            n = h || (h = c(e)), i = u.bind(null, n, a, !1), r = u.bind(null, n, a, !0);
          } else n = c(e), i = p.bind(null, n, e), r = function () {
            !function (t) {
              if (null === t.parentNode) return !1;
              t.parentNode.removeChild(t);
            }(n);
          };

          return i(t), function (e) {
            if (e) {
              if (e.css === t.css && e.media === t.media && e.sourceMap === t.sourceMap) return;
              i(t = e);
            } else r();
          };
        }

        t.exports = function (t, e) {
          (e = e || {}).singleton || "boolean" == typeof e.singleton || (e.singleton = (void 0 === i && (i = Boolean(window && document && document.all && !window.atob)), i));
          var n = s(t = t || [], e);
          return function (t) {
            if (t = t || [], "[object Array]" === Object.prototype.toString.call(t)) {
              for (var i = 0; i < n.length; i++) {
                var r = o(n[i]);
                a[r].references--;
              }

              for (var c = s(t, e), l = 0; l < n.length; l++) {
                var d = o(n[l]);
                0 === a[d].references && (a[d].updater(), a.splice(d, 1));
              }

              n = c;
            }
          };
        };
      },
      962: t => {
        t.exports = "<svg viewBox=\"0 0 400 400\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M200.48 53c10.359 0 19.704 2.99 27.99 7.933 5.625 3.356 10.563 7.492 15.193 12.354 6.735 7.072 13.08 15.997 19.645 27.12l.142.24 76.986 134.194c6.553 10.46 11.425 19.799 14.654 28.577 2.216 6.027 3.735 11.967 4.393 18.103.963 8.977.067 18.035-3.552 26.98-7.933 19.612-24.283 33.336-45.054 37.586l-4.464.913h-204.65l-2.817-.357c-10.267-1.3-19.764-4.163-28.422-9.16-11.051-6.377-19.82-15.823-25.055-27.664-4.432-10.03-5.235-19.952-3.914-29.887.821-6.175 2.486-12.239 4.864-18.58 3.616-9.64 9.159-20.55 16.718-33.309l74.632-127.44c6.469-11.125 12.743-20.061 19.436-27.158 4.62-4.899 9.562-9.07 15.206-12.456 8.301-4.979 17.68-7.989 28.07-7.989zm0 45c-6.102 0-14.05 8.427-23.842 25.28l-74.73 127.605c-12.713 21.444-17.806 35.025-15.28 40.742 2.527 5.717 8.519 9.175 17.974 10.373h197.255c5.932-1.214 10.051-4.671 12.357-10.373 2.307-5.702-1.812-16.903-12.357-33.603l-77.302-134.743C214.608 106.427 206.583 98 200.48 98zm.02 135c14.636 0 26.5 11.864 26.5 26.5S215.136 286 200.5 286 174 274.136 174 259.5s11.864-26.5 26.5-26.5zm0-83c12.426 0 22.5 10.074 22.5 22.5v28c0 12.426-10.074 22.5-22.5 22.5S178 212.926 178 200.5v-28c0-12.426 10.074-22.5 22.5-22.5z\"></path></svg>";
      },
      737: t => {
        t.exports = "<svg viewBox=\"0 0 400 400\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M281.488 301c6.388 0 12.311-1.14 17.768-3.42 5.457-2.28 10.315-5.433 14.574-9.456a43.696 43.696 0 009.983-14.084c2.395-5.365 3.593-11.133 3.593-17.303 0-10.999-2.994-20.388-8.984-28.167-5.99-7.78-14.707-11.67-26.153-11.67-5.324 0-10.249.805-14.774 2.415-4.526 1.61-7.587 3.085-9.184 4.426a165.146 165.146 0 01-1.997-9.054 46.483 46.483 0 01-.798-8.651c0-9.658 1.664-18.242 4.991-25.753 3.328-7.511 7.92-14.486 13.776-20.924 5.856-6.439 12.777-12.609 20.763-18.51 7.986-5.902 16.637-11.938 25.954-18.108L313.032 99c-12.778 6.975-24.89 14.352-36.336 22.131-11.446 7.78-21.562 16.9-30.346 27.363-8.785 10.462-15.706 22.534-20.763 36.215-5.058 13.681-7.587 29.777-7.587 48.287 0 7.511 1.264 15.29 3.793 23.339 2.53 8.047 6.389 15.358 11.58 21.93 5.19 6.572 11.779 12.005 19.765 16.297 7.986 4.292 17.436 6.438 28.35 6.438zm-148 0c6.388 0 12.311-1.14 17.768-3.42 5.457-2.28 10.315-5.433 14.574-9.456a43.696 43.696 0 009.983-14.084c2.395-5.365 3.593-11.133 3.593-17.303 0-10.999-2.994-20.388-8.984-28.167-5.99-7.78-14.707-11.67-26.153-11.67-5.324 0-10.249.805-14.774 2.415-4.526 1.61-7.587 3.085-9.184 4.426a165.146 165.146 0 01-1.997-9.054 46.483 46.483 0 01-.798-8.651c0-9.658 1.664-18.242 4.991-25.753 3.328-7.511 7.92-14.486 13.776-20.924 5.856-6.439 12.777-12.609 20.763-18.51 7.986-5.902 16.637-11.938 25.954-18.108L165.032 99c-12.778 6.975-24.89 14.352-36.336 22.131-11.446 7.78-21.562 16.9-30.346 27.363-8.785 10.462-15.706 22.534-20.763 36.215C72.529 198.39 70 214.486 70 232.996c0 7.511 1.264 15.29 3.793 23.339 2.53 8.047 6.389 15.358 11.58 21.93 5.19 6.572 11.779 12.005 19.765 16.297 7.986 4.292 17.436 6.438 28.35 6.438z\"></path></svg>";
      },
      399: t => {
        t.exports = "<svg viewBox=\"0 0 400 400\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M204.615 84L288 320h-53.354l-15.175-48.513h-86.74L116.437 320H65l84.024-236h55.59zm-28.275 53.957l-30.35 92.863h59.743l-29.393-92.863zm211.05-36.018l.256.25c5.579 5.578 5.662 14.57.25 20.25l-.25.256-45.49 45.49c-5.577 5.578-14.57 5.66-20.25.25l-.255-.25-45.49-45.49c-5.663-5.663-5.663-14.844 0-20.506 5.578-5.578 14.57-5.662 20.25-.25l.256.25 35.236 35.236 35.237-35.236c5.578-5.578 14.57-5.662 20.25-.25z\"></path></svg>";
      }
    },
        e = {};

    function n(i) {
      var r = e[i];
      if (void 0 !== r) return r.exports;
      var a = e[i] = {
        id: i,
        exports: {}
      };
      return t[i](a, a.exports, n), a.exports;
    }

    n.n = t => {
      var e = t && t.__esModule ? () => t.default : () => t;
      return n.d(e, {
        a: e
      }), e;
    }, n.d = (t, e) => {
      for (var i in e) n.o(e, i) && !n.o(t, i) && Object.defineProperty(t, i, {
        enumerable: !0,
        get: e[i]
      });
    }, n.o = (t, e) => Object.prototype.hasOwnProperty.call(t, e);
    var i = {};
    return (() => {
      "use strict";

      n.d(i, {
        default: () => p
      });
      var t = n(379),
          e = n.n(t),
          r = n(771);

      function a(t, e = null, n = {}) {
        const i = document.createElement(t);
        Array.isArray(e) ? i.classList.add(...e) : e && i.classList.add(e);

        for (const t in n) i[t] = n[t];

        return i;
      }

      e()(r.Z, {
        insert: "head",
        singleton: !1
      }), r.Z.locals;
      var o = n(962),
          s = n.n(o),
          c = n(737),
          l = n.n(c),
          d = n(399),
          u = n.n(d);

      class p {
        constructor({
          api: t,
          data: e,
          config: n,
          block: i
        }) {
          this.api = t, this.data = e, this.config = n, this.block = i, this.variants = [{
            name: "call-out",
            icon: s(),
            title: this.api.i18n.t("Call-out")
          }, {
            name: "citation",
            icon: l(),
            title: this.api.i18n.t("Citation")
          }, {
            name: "details",
            icon: u(),
            title: this.api.i18n.t("Details")
          }], this.wrapper = void 0;
        }

        static get isTune() {
          return !0;
        }

        static get CSS() {
          return {
            toggler: "cdx-text-variant__toggler"
          };
        }

        render() {
          const t = a("div", "");
          return this.variants.forEach(({
            name: e,
            icon: n,
            title: i
          }) => {
            const r = a("div", [this.api.styles.settingsButton, p.CSS.toggler], {
              innerHTML: n
            });
            r.dataset.name = e, this.api.tooltip.onHover(r, i, {
              placement: "top",
              hidingDelay: 500
            }), t.appendChild(r);
          }), this.api.listeners.on(t, "click", t => {
            this.tuneClicked(t);
          }), t;
        }

        tuneClicked(t) {
          const e = t.target.closest(`.${this.api.styles.settingsButton}`),
                n = e.classList.contains(this.api.styles.settingsButtonActive);
          e.classList.toggle(this.api.styles.settingsButtonActive, !n), this.variant = n ? "" : e.dataset.name;
        }

        wrap(t) {
          return this.wrapper = a("div"), this.variant = this.data, this.wrapper.appendChild(t), this.wrapper;
        }

        set variant(t) {
          this.data = t, this.variants.forEach(t => {
            this.wrapper.classList.toggle(`cdx-text-variant--${t.name}`, t.name === this.data);
          });
        }

        save() {
          return this.data || "";
        }

      }
    })(), i.default;
  })();
});
export default exports;
export const TextVariantTune = exports.TextVariantTune;